import { FC } from 'react';
import { WithRouterProps } from 'next/dist/client/with-router';
import { connect } from 'react-redux';
import { withRouter } from 'next/router';
import dynamic from 'next/dynamic';
import { XWISDocumentPropType } from '@Shared/webparts/Catalogue/CatalogueModals/XWISDocumentModal';
import { System } from '@Reducers/user/models';
import { RootState } from '@Redux';
import { CustomRouteType } from '@Constants/legal';
import AddVehicleModal from '../AddVehicleModal';
import DeleteVehicleModal from '../DeleteVehicleModal';
import RequestCreatedModal from '../company-primary-info-modal/RequestCreatedModal';
import PartsOrderDetailsModal from '../PartsOrderDetailsModal';
import AddToBasketDirectModal from '../AddToBasketDirectModal';
import ErrorModal from '../ErrorModal';
import VehicleModals from './vehicle';

const ImprintModal = dynamic(() => import('@Shared/modals/ImprintModal'), { ssr: false });
const OrderRatingModal = dynamic(() => import('@Shared/modals/OrderRatingModal'), { ssr: false });
const OrderDetailModal = dynamic(() => import('@Shared/modals/OrderDetailModal'), { ssr: false });
const DealerLocator = dynamic(() => import('@Shared/dealer-locator/DealerLocator'), { ssr: false });
const RequestModal = dynamic(() => import('@Shared/modals/AccessRequestModal'), { ssr: false });
const FlyoutMenu = dynamic(() => import('@Shared/header/FlyoutMenu'), { ssr: false });
const Registration = dynamic(() => import('@Shared/registration/Registration'), { ssr: false });
const ConsentSettings = dynamic(() => import('@Shared/modals/Usercentrics/ConsentSettingsModal'), { ssr: false });
const RequestModalSuccess = dynamic(() => import('@Shared/modals/AccessRequestModal/Success'), { ssr: false });
const Banner = dynamic(() => import('@Shared/modals//Usercentrics/Banner'), { ssr: false });
const FastAvailabilityModal = dynamic(() => import('@Shared/modals/FastAvailabilityModal/FastAvailabilityModal'), {
	ssr: false,
});
const CompanyDataEditModal = dynamic(() => import('@Shared/modals/company-primary-info-modal/index'), { ssr: false });
const BannerNotificationModal = dynamic(() => import('@Shared/modals/BannerNotificationModal/BannerNotificationModal'));
const CatalogOptionSelectionModal = dynamic(
	() => import('@Shared/webparts/Catalogue/CatalogueModals/CatalogSelectionModal'),
	{ ssr: false }
);
const CatalogueFilterModal = dynamic(
	() => import('@Shared/webparts/Catalogue/CatalogueModals/CatalogueFilterModal/CatalogueFilterModal'),
	{ ssr: false }
);
const ColorSelectionModal = dynamic(
	() => import('@Shared/webparts/Catalogue/CatalogueModals/ColorSelection/ColorSelectionModal'),
	{
		ssr: false,
	}
);
const ChangeHardwareIdModal = dynamic(
	() => import('@Partials/Account/Licenses/DiagnosisLicense/ChangeHardwareIDModal'),
	{ ssr: false }
);
const ColorCodeInfoModal = dynamic(
	() => import('@Shared/webparts/Catalogue/CatalogueModals/ColorCodeInfoModal/index'),
	{
		ssr: false,
	}
);
const XWISDocumentModal = dynamic(() => import('@Shared/webparts/Catalogue/CatalogueModals/XWISDocumentModal'), {
	ssr: false,
});
const AlternatePartSelectionModal = dynamic(
	() => import('@Shared/webparts/Catalogue/CatalogueModals/AlternatePartModal'),
	{
		ssr: false,
	}
);
const ReplacementChainModal = dynamic(
	() => import('@Shared/webparts/Catalogue/CatalogueModals/ReplacementChainModal'),
	{
		ssr: false,
	}
);
const PartPackageModal = dynamic(() => import('@Shared/webparts/Catalogue/PartPackage/PartPackageModal'), {
	ssr: false,
});
const RemanPartsModal = dynamic(() => import('@Shared/modals/RemanPartsModal'), { ssr: false });
const RemanPartsRequestModal = dynamic(() => import('@Shared/modals/RemanPartsRequestModal'), { ssr: false });
const FreeTextSearchModal = dynamic(
	() => {
		return import('@Shared/webparts/Catalogue/PartSearch/FreeTextSearchModal/FreeTextSearchModal');
	},
	{ ssr: false }
);
const ModelRangeSelectionModel = dynamic(
	() => {
		return import('@Shared/webparts/Catalogue/PartSearch/ModelRangeSelectionModal/index');
	},
	{ ssr: false }
);
const FootnoteModal = dynamic(() => import('@Shared/webparts/Catalogue/CatalogueModals/Footnote/index'), {
	ssr: false,
});
const InformationNotification = dynamic(() => import('@Partials/Vehicle/InformationNotification/index'), {
	ssr: false,
});
const BulkOrderModal = dynamic(() => import('@Shared/modals/BulkOrderModal/index'), {
	ssr: false,
});
const OpeningHoursModal = dynamic(() => import('@Shared/modals/OpeningHoursModal/index'), {
	ssr: false,
});
const LimitedAdminRightsModal = dynamic(() => import('@Shared/modals/LimitedAdminRightsModal'), {
	ssr: false,
});
const ContentReportingModal = dynamic(() => import('@Shared/modals/ContentReportingModal'), {
	ssr: false,
});
const SpecialModificationVehicleModal = dynamic(() => import('@Shared/modals/SpecialModificationVehicleModal'), {
	ssr: false,
});
const AggregateNumberSelectionModal = dynamic(() => import('@Shared/modals/AggregateNumberSelectionModal'), {
	ssr: false,
});
const BasketDeleteConfirmationModal = dynamic(() => import('@Shared/modals/BasketDeleteConfirmationModal'), {
	ssr: false,
});
const RetailerDeleteConfirmationModal = dynamic(() => import('@Shared/modals/RetailerDeleteConfirmationModal'), {
	ssr: false,
});
const AddOldVehicleConfirmModal = dynamic(() => import('@Shared/modals/AddOldVehicleConfirmModal'), {
	ssr: false,
});
const ModelIdentificationModal = dynamic(
	() => import('@Shared/webparts/Catalogue/CatalogueModals/ModelIdentificationModal'),
	{ ssr: false }
);
const LuckyDrawParticipationSuccessModal = dynamic(() => import('@Shared/modals/LuckyDrawParticipationSuccessModal'), {
	ssr: false,
});
const NoActiveLuckyDrawModal = dynamic(() => import('@Shared/modals/NoActiveLuckyDrawModal'), {
	ssr: false,
});
const UnlockViaPPVModal = dynamic(() => import('@Shared/webparts/Catalogue/CatalogueModals/UnlockViaPPVModal'), {
	ssr: false,
});
export interface ModalWrapperProps extends WithRouterProps {
	customRoute?: CustomRouteType;
	showEnterVinModal: boolean;
	showOrderRatingModal: boolean;
	showOrderDetailModal: boolean;
	showDealerLocator?: boolean;
	showAccessRequestModal: boolean;
	showAccessRequestSuccessModal: boolean;
	showUsercentricsSettingsModal?: boolean;
	showUsercentricsBanner?: boolean;
	showRegistrationModal?: boolean;
	showChangeHardwareIdModal?: boolean;
	system?: System;
	selectedProductKey?: string;
	showRemanPartsModal: boolean;
	showRemanPartsRequestModal: boolean;
	showFasterAvailableModal: boolean;
	showColorSelectionModal: boolean;
	showAddVehicleModal?: boolean;
	showDeleteVehicleModal?: boolean;
	showMegaMenu: boolean;
	showColorCodeInfoModal: boolean;
	showAlternatePartModal: boolean;
	showReplacementChainModal: boolean;
	showCompanyDataModal: boolean;
	showRequestCreatedModal: boolean;
	showPartPackageModal: boolean;
	showFreeTextSearchModal: boolean;
	showModelRangeSelectionModal: boolean;
	showFootnoteModal: boolean;
	showNotificationBannerModal: boolean;
	showImprintModal: boolean;
	showAdditionalInfoModal: boolean;
	showBulkOrderModal: boolean;
	showOpeningHoursModal: boolean;
	showPartsOrderDetailsModal: boolean;
	showLimitedAdminAccessModal: boolean;
	showContentReportingModal: boolean;
	showSpecialModificationVehicleModal: boolean;
	showAggregateNumberSelectionModal: boolean;
	showAddOldVehicleConfirmModal: boolean;
	showBasketDeleteConfirmationModal: boolean;
	showModelIdentificationModal: boolean;
	showCatalogueFilterModal: boolean;
	showCatalogOptionSelectionModal: boolean;
	showRetailerDeleteConfirmationModal: boolean;
	showAddBasketDirectlyModal: boolean;
	showErrorModal: boolean;
	showLuckyDrawParticipationSuccessModal: boolean;
	showNoActiveLuckyDrawModal: boolean;
	showUnlockViaPPVModal: boolean;
	xwisDocumentPartProps: XWISDocumentPropType;
}

const ModalWrapper: FC<ModalWrapperProps> = ({
	customRoute = null,
	showOrderRatingModal,
	showOrderDetailModal,
	showDealerLocator,
	showAccessRequestModal,
	showAccessRequestSuccessModal,
	showUsercentricsSettingsModal,
	showRegistrationModal,
	showChangeHardwareIdModal,
	system,
	selectedProductKey,
	showUsercentricsBanner,
	showRemanPartsModal,
	showRemanPartsRequestModal,
	showFasterAvailableModal,
	showColorSelectionModal,
	showAddVehicleModal,
	showDeleteVehicleModal,
	showMegaMenu,
	showColorCodeInfoModal,
	showAlternatePartModal,
	showReplacementChainModal,
	showCompanyDataModal,
	showRequestCreatedModal,
	showPartPackageModal,
	showFreeTextSearchModal,
	showModelRangeSelectionModal,
	showFootnoteModal,
	showNotificationBannerModal,
	showImprintModal,
	showAdditionalInfoModal,
	showBulkOrderModal,
	showOpeningHoursModal,
	showPartsOrderDetailsModal,
	showLimitedAdminAccessModal,
	showContentReportingModal,
	showSpecialModificationVehicleModal,
	showAggregateNumberSelectionModal,
	showAddOldVehicleConfirmModal,
	showBasketDeleteConfirmationModal,
	showModelIdentificationModal,
	showCatalogueFilterModal,
	showCatalogOptionSelectionModal,
	showRetailerDeleteConfirmationModal,
	showAddBasketDirectlyModal,
	showErrorModal,
	showLuckyDrawParticipationSuccessModal,
	showNoActiveLuckyDrawModal,
	showUnlockViaPPVModal,
	xwisDocumentPartProps,
}) => {
	return (
		<>
			{showUsercentricsBanner && <Banner />}
			{showUsercentricsSettingsModal && <ConsentSettings />}
			{showAccessRequestSuccessModal && <RequestModalSuccess />}
			{showAccessRequestModal && <RequestModal />}
			{showDealerLocator && <DealerLocator />}
			{showOrderDetailModal && <OrderDetailModal />}
			{showOrderRatingModal && <OrderRatingModal />}
			{showRegistrationModal && <Registration />}
			{showChangeHardwareIdModal && system && selectedProductKey && <ChangeHardwareIdModal />}
			{showRemanPartsModal && <RemanPartsModal />}
			{showRemanPartsRequestModal && <RemanPartsRequestModal />}
			{showAddVehicleModal && <AddVehicleModal />}
			{showDeleteVehicleModal && <DeleteVehicleModal />}
			{showMegaMenu && <FlyoutMenu customRoute={customRoute} />}
			{showCatalogOptionSelectionModal && <CatalogOptionSelectionModal />}
			{showCatalogueFilterModal && <CatalogueFilterModal />}
			{showReplacementChainModal && <ReplacementChainModal />}
			{showColorCodeInfoModal && <ColorCodeInfoModal />}
			{showAlternatePartModal && <AlternatePartSelectionModal />}
			{showCompanyDataModal && <CompanyDataEditModal />}
			{showRequestCreatedModal && <RequestCreatedModal />}
			{showPartPackageModal && <PartPackageModal />}
			{showFreeTextSearchModal && <FreeTextSearchModal />}
			{showModelRangeSelectionModal && <ModelRangeSelectionModel />}
			{showFootnoteModal && <FootnoteModal />}
			{showFasterAvailableModal && <FastAvailabilityModal />}
			{showColorSelectionModal && <ColorSelectionModal />}
			{showNotificationBannerModal && <BannerNotificationModal />}
			{showImprintModal && <ImprintModal />}
			{showAdditionalInfoModal && <InformationNotification />}
			{showBulkOrderModal && <BulkOrderModal />}
			{showOpeningHoursModal && <OpeningHoursModal />}
			{showPartsOrderDetailsModal && <PartsOrderDetailsModal />}
			{xwisDocumentPartProps && <XWISDocumentModal />}
			{showLimitedAdminAccessModal && <LimitedAdminRightsModal />}
			{showUnlockViaPPVModal && <UnlockViaPPVModal />}
			{showContentReportingModal && <ContentReportingModal />}
			{showSpecialModificationVehicleModal && <SpecialModificationVehicleModal />}
			{showAggregateNumberSelectionModal && <AggregateNumberSelectionModal />}
			{showAddOldVehicleConfirmModal && <AddOldVehicleConfirmModal />}
			{showBasketDeleteConfirmationModal && <BasketDeleteConfirmationModal />}
			{showModelIdentificationModal && <ModelIdentificationModal />}
			{showRetailerDeleteConfirmationModal && <RetailerDeleteConfirmationModal />}
			{showAddBasketDirectlyModal && <AddToBasketDirectModal />}
			{showErrorModal && <ErrorModal />}
			{showLuckyDrawParticipationSuccessModal && <LuckyDrawParticipationSuccessModal />}
			{showNoActiveLuckyDrawModal && <NoActiveLuckyDrawModal />}
			<VehicleModals />
		</>
	);
};

const mapStateToProps = (state: RootState) => ({
	showEnterVinModal: state.modals.showEnterVinModal,
	showOrderRatingModal: state.modals.showOrderRatingModal,
	showOrderDetailModal: state.modals.showOrderDetailModal,
	showDealerLocator: state.modals.showDealerLocator,
	showAccessRequestModal: state.modals.showAccessRequestModal,
	showAccessRequestSuccessModal: state.modals.showAccessRequestSuccessModal,
	showUsercentricsSettingsModal: state.modals.showUsercentricsSettingsModal,
	showUsercentricsBanner: state.modals.showUsercentricsBanner,
	showRegistrationModal: state.modals.showRegistrationModal,
	showChangeHardwareIdModal: state.modals.showChangeHardwareIdModal,
	system: state.modals.system,
	selectedProductKey: state.modals.selectedProductKey,
	showRemanPartsModal: state.modals.showRemanPartsModal,
	showRemanPartsRequestModal: state.modals.showRemanPartsRequestModal,
	showFasterAvailableModal: state.modals.showFasterAvailableModal,
	showColorSelectionModal: state.modals.showColorSelectionModal,
	showAddVehicleModal: state.modals.showAddVehicleModal,
	showDeleteVehicleModal: state.modals.showDeleteVehicleModal,
	showMegaMenu: state.modals.showMegaMenu,
	showColorCodeInfoModal: state.modals.showColorCodeInfoModal,
	showCatalogueFilterModal: state.modals.showCatalogueFilterModal,
	showCatalogOptionSelectionModal: state.modals.showCatalogOptionSelectionModal,
	showAlternatePartModal: state.modals.showAlternatePartModal,
	showReplacementChainModal: state.modals.showReplacementChainModal,
	showCompanyDataModal: state.modals.showCompanyDataModal,
	showRequestCreatedModal: state.modals.showRequestCreatedModal,
	showPartPackageModal: state.modals.showPartPackageModal,
	showFreeTextSearchModal: state.modals.showFreeTextSearchModal,
	showModelRangeSelectionModal: state.modals.showModelRangeSelectionModal,
	showFootnoteModal: state.modals.showFootnoteModal,
	showNotificationBannerModal: state.modals.showNotificationBannerModal,
	showImprintModal: state.modals.showImprintModal,
	showAdditionalInfoModal: state.modals.showAdditionalInfoModal,
	showBulkOrderModal: state.modals.showBulkOrderModal,
	showOpeningHoursModal: state.modals.showOpeningHoursModal,
	showPartsOrderDetailsModal: state.modals.showPartsOrderDetailsModal,
	showLimitedAdminAccessModal: state.modals.showLimitedAdminAccessModal,
	showUnlockViaPPVModal: state.modals.showUnlockViaPPVModal,
	showContentReportingModal: state.modals.showContentReportingModal,
	showSpecialModificationVehicleModal: state.modals.showSpecialModificationVehicleModal,
	showAggregateNumberSelectionModal: state.modals.showAggregateNumberSelectionModal,
	showAddOldVehicleConfirmModal: state.modals.showAddOldVehicleConfirmModal,
	showBasketDeleteConfirmationModal: state.modals.showBasketDeleteConfirmationModal,
	showModelIdentificationModal: state.modals.showModelIdentificationModal,
	showRetailerDeleteConfirmationModal: state.modals.showRetailerDeleteConfirmationModal,
	showAddBasketDirectlyModal: state.modals.showAddBasketDirectlyModal,
	showErrorModal: state.modals.showErrorModal,
	showLuckyDrawModal: state.modals.showLuckyDrawModal,
	showLuckyDrawParticipationSuccessModal: state.modals.showLuckyDrawParticipationSuccessModal,
	showNoActiveLuckyDrawModal: state.modals.showNoActiveLuckyDrawModal,
	xwisDocumentPartProps: state.modals.xwisDocumentPartProps,
});

export default connect(mapStateToProps)(withRouter(ModalWrapper));
