import { VFC, useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import cN from 'classnames';
import B2BConnectLogo from '@Static/images/b2b-connect-logo.png';
import NextI18Next, { useTranslation } from '@Lib/i18n';
import { login } from '@User-operations';
import { getFocalPointClass } from '@Umbraco/media';
import { HomePageData, toBenefitModuleData, toPlatformPreviewModuleData, toQuoteModuleData } from '@Umbraco/home';
import Button, { ButtonVariant } from '@isp/button';
import { IconType } from '@isp/icon';
import { useWindowIntersect } from '@isp/sticky/useWindowIntersect';
import GlanceModule from '@Partials/NewHome/Modules/GlanceModule';
import BenefitsModule from '@Partials/NewHome/Modules/BenefitsModule';
import PlatformPreviewModule from '@Partials/NewHome/Modules/PlatformPreviewModule';
import RegisterModule from '@Partials/NewHome/Modules/RegisterModule';
import QuoteModule from '@Partials/NewHome/Modules/QuoteModule';
import CMSContent, { CMSContentThemes } from '@Shared/cms-content/CMSContent';
import { PushDataToTagManagerForButtons, PushEventToDataLayer } from '@Helpers/google-analytics/pushDataToTagManager';
import { geti18nLanguage } from '@Helpers/language/geti18nLanguage';
import { useResize } from '@Helpers/hooks/useEvent';
import getSalesOffers from '@Umbraco/sales-offers';
import CampaignBanner from '@Shared/CampaignBanner';
import { Buttons, Components, EventActions, EventCategories, Pages, getComponentId } from '@Constants/google-analytics';
import {
	HOME_LOGO_HEIGHT,
	HOME_LOGO_WIDTH,
	HomeModules,
	MARGIN_TOP_SCROLL_BUTTON,
	STICKY_TOP_SCOLL_BUTTON,
} from '@Constants/home';
import { Events } from '@Constants/common';
import RegisterButton from './Shared/RegisterButton';
import './index.scss';

const Home: VFC<HomePageData> = ({ sections = [], headerBanner }) => {
	const [offers, setOffers] = useState([]);
	const registrationRef = useRef(null);
	const registrationRefModule = useRef(null);
	const language = geti18nLanguage(NextI18Next.i18n.language);
	const { market } = useRouter().query;

	const getSection = data => {
		const sectionMap = {
			[HomeModules.AT_A_GLANCE_MODULE]: <GlanceModule data={data} />,
			[HomeModules.BENEFITS_MODULE]: <BenefitsModule {...toBenefitModuleData(data)} />,
			[HomeModules.PLATFORM_PREVIEW_MODULE]: <PlatformPreviewModule {...toPlatformPreviewModuleData(data)} />,
			[HomeModules.QUOTE_MODULE]: <QuoteModule {...toQuoteModuleData(data)} />,
			[HomeModules.REGISTER_MODULE]: <RegisterModule data={data} registrationRef={registrationRefModule} />,
		};
		const { identifier } = data;

		return sectionMap[identifier];
	};

	const fetchOffers = () =>
		getSalesOffers(market as string, language).then(res => {
			setOffers(res);
		});

	useEffect(() => {
		fetchOffers();
	}, [language]);

	return (
		<>
			<div className="public-home disp-grid">
				<HeaderBanner headerBanner={headerBanner} registrationRef={registrationRef} />
				{sections?.map(section => (
					<div key={section._id}>{getSection(section)}</div>
				))}
				<StickyRegisterButton registrationRef={registrationRef} registrationRefModule={registrationRefModule} />
			</div>
			{offers && <CampaignBanner campaignItems={offers} autoplay />}
		</>
	);
};

const StickyRegisterButton = ({ registrationRef, registrationRefModule }) => {
	const isRegisterInViewport = useWindowIntersect(registrationRef);
	const isRegisterModuleInViewport = useWindowIntersect(registrationRefModule);
	const showStickyButton = registrationRef?.current && registrationRefModule?.current;

	return (
		showStickyButton && (
			<div className="public-home__register" data-visible={!isRegisterInViewport && !isRegisterModuleInViewport}>
				<RegisterButton className="p-h-10" id={getComponentId([Pages.HOME, Components.STICKY, Buttons.REGISTER])} />
			</div>
		)
	);
};

const HeaderBanner = ({ headerBanner, registrationRef }) => (
	<div
		className={cN(
			'public-home__banner',
			`${getFocalPointClass(headerBanner?.image?.focalPointTop, headerBanner?.image?.focalPointLeft, 'background')}`
		)}
		style={{ backgroundImage: `url("${headerBanner?.image?.url}")` }}
	>
		<img
			className="w-100-p h-100-p"
			src={headerBanner?.image?.url}
			style={{ visibility: 'hidden' }}
			alt={headerBanner?.image?.altText || 'B2B Connect'}
		/>
		<div className="public-home__banner__content color-white p-v-10 p-r-10 p-l-10">
			<div className="train__justify-center disp-flex-column">
				<img
					alt="B2B Connect"
					src={B2BConnectLogo}
					width={HOME_LOGO_WIDTH}
					height={HOME_LOGO_HEIGHT}
					className="m-b-12"
				/>
				<h1 className="DaimlerCAC-Regular fs-80 lh-normal fw-normal">{headerBanner.title}</h1>
				<CMSContent theme={CMSContentThemes.LIGHT} content={headerBanner.description} contentClassName="p-0 fs-18" />
				<BannerRegister registrationRef={registrationRef} />
			</div>
			<ScrollButton />
		</div>
	</div>
);

const BannerRegister = ({ registrationRef }) => {
	const { t } = useTranslation(['common']);

	const onLoginClick = e => {
		PushDataToTagManagerForButtons(e.target.id, window.location.href);
		PushEventToDataLayer(Events.CLICK, {
			category: EventCategories.LINK,
			action: EventActions.LOGIN,
		});
		login();
	};

	return (
		<div className="public-home__banner__register m-t-5">
			<Button
				outline
				onClick={onLoginClick}
				className="p-h-4 fs-16"
				variant={ButtonVariant.TERTIARY}
				id={getComponentId([Pages.HOME, Components.HEADER, Components.LOGIN, Buttons.LOGIN])}
			>
				{t('common:login')}
			</Button>
			<RegisterButton
				registrationRef={registrationRef}
				id={getComponentId([Pages.HOME, Components.BANNER, Buttons.REGISTER])}
			/>
		</div>
	);
};

const ScrollButton = () => {
	const { t } = useTranslation(['common']);
	const [distanceToScrollButton, setDistanceToScrollButton] = useState(0);

	const handleTopDistance = () => {
		const topElement = document.querySelector(`.public-home__banner__register`);
		setDistanceToScrollButton(window.scrollY + topElement.getBoundingClientRect().bottom + STICKY_TOP_SCOLL_BUTTON);
	};

	useResize(handleTopDistance);

	useEffect(() => {
		handleTopDistance();
	}, []);

	const onClick = () => {
		const nextModule = document.querySelector(`.home__modules`);
		const yCoordinate = window.scrollY + nextModule.getBoundingClientRect().top - MARGIN_TOP_SCROLL_BUTTON;
		window.scroll({ top: yCoordinate, behavior: 'smooth' });
	};

	return (
		distanceToScrollButton !== 0 && (
			<div className="public-home__scroll" style={{ top: `${distanceToScrollButton}px` }}>
				<Button onClick={onClick} variant={ButtonVariant.FLAT_LIGHT} startIconType={IconType.ARROW_DOWN}>
					{t('common:scroll')}
				</Button>
			</div>
		)
	);
};

export default Home;
